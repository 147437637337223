<template>
  <div class="body_in">
    <!--    顶部-->
    <!--    导航栏-->
    <TopNavigation  class="wb" :getSonData="getSonData"></TopNavigation>
    <router-view></router-view>
    <!--底部-->
    <MyFloor></MyFloor>
  </div>

</template>

<script>
import TopNavigation from "@/components/TopNavigation";
import MyFloor from "@/components/MyFloor";
import router from "@/router";

export default {
  name: 'App',
  data() {
    return {
      'pageName':''
    }
  },
  components: {
    TopNavigation,
    MyFloor
  },
  methods: {
    getSonData: function (name) {
      this.pageName = name;
      router.push(this.pageName);
    }
  }
}

</script>
<style>
body {
  cursor: pointer;
  padding: 0px;
  margin: 0px;
}
.body_in{
  text-align:center;
  display:inline-block;
  width: 100%;
}
.wb {
  position: sticky;
  z-index: 1;
  top: 0;
}

</style>
