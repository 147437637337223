<template>
    <div class="wc">
      <div class="zb">
        <p>联系电话：028—63956985</p>
        <p>公司地址：四川省成都市成华区万科金库925室</p>
  <!--        <p>友情链接：<a></a></p>-->
        <a  href="https://beian.miit.gov.cn">	蜀ICP备2022025625号-1</a>
        | © 2022  <a href="www.dwjzkj.com">四川鼎王金猪</a>. 版权所有 </div>
      </div>
<!--    <div id="footer">-->

<!--  </div>-->
</template>

<script>
export default {
  name: "MyFloor"
}
</script>

<style scoped>
/*外层*/
.wc {
  width:100%;
  height: auto;
  padding: 30px 0px 10px  0px;
  background-color: #333;
}
a{
  color:#7e7e7e;
  text-decoration:none;
}
a:hover{
  color:#de4911;
  text-decoration:none;
}
/*中部*/
.zb {
  color: #7e7e7e;
  text-align: center;
  height:auto;
  width: 100%;
  background-color: #333;
}
.content {
   height: 1800px;
   width: 100%;
   text-align: center;
 }
#footer {
  color: #7e7e7e;
  height: 40px;
  line-height: 40px;
  position: fixed;
  bottom: 0;
  width: 100%;
  text-align: center;
  background: #333;
  font-family: Arial;
  font-size: 12px;
  letter-spacing: 1px;
}
</style>
