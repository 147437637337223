<template>
  <div class="body_div">
    <el-container>
      <el-aside width="400px" style="background-color: white;   padding-top: 10px;  ">
        <img  :src="log" style="width: 160px;"  alt="鼎王金猪">
      </el-aside>
      <el-main>
        <ul class="nav">
          <li><a @click="getSonData('/')">首页</a></li>
          <!--<li><a>服务项目</a></li>-->
          <li><a @click="getSonData('/product')">产品与服务</a></li>
          <li><a @click="getSonData('/regard')">关于鼎王</a></li>
          <li><a @click="getSonData('/news')">新闻中心</a></li>
          <li><a @click="getSonData('/science')">鼎王科普</a></li>
          <li><a @click="getSonData('/entrance')">进口猪精</a></li>
<!--          <li><a @click="getSonData()">联系我们</a></li>-->
        </ul>
      </el-main>
      <el-aside width="400px" style="background-color: white; ">
        <div style="display: inline-block; height: auto; font-size: 20px; ">
          <img :src="phone" style="display: inline-block;">
              <span style="   "> 028-63956985</span>
        </div>
      </el-aside>
    </el-container>
    <!--      <div style="padding-left: 20px;background-color: #4a78da;height:100%"></div>-->
  </div>
</template>
<script>
import log from '@/assets/img/log.png';
import  phone from '@/assets/svg/phone.svg';
export default {
  name: "TopNavigation",
  props: ['getSonData'],
  data() {
    return {
      log,
      phone,
    }
  }
}
</script>

<style scoped>
.body_div{
    width:100%;
    height: auto;
  }
* {
  box-sizing: border-box;
}

li a {
  display: inline-block;
  color: black;
  text-align: center;
  padding: 5px 15px;
}

.nav {
  background-color: white;
  list-style-type: none;
  text-align: center;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

.nav li {
  display: inline-block;
  font-weight: 500;
  padding: 0px;
}

.nav a {
  display: inline-block;
  font-size: 18px;
  font-weight: 500;
  padding: 20px 40px 20px 40px;
}

li a:hover {
  color: #de4911;
  font-size: 20px;
  border-bottom: 1px solid #de4911;
  font-weight: 600;
  background-color: white;
}

.el-aside {
  text-align: center;
  padding-top: 20px;
}

.el-main {
  text-align: center;
  padding: 0px;
  top: 50%;

}

img{
  border-radius:10px;
  display: inline;
}

</style>
