import { createRouter,createWebHashHistory } from "vue-router";
const routes = [
    {
        path:'/',
        name:'home',
        component:()=>import("@/components/Home.vue")
    },
    {
        path:'/brief',
        name:'brief',
        component:()=>import("@/components/Brief.vue")
    },
    {
        path:'/regard',
        name:'regard',
        component:()=>import("@/components/Regard.vue")
    },
    {
        path:'/product',
        name:'Product',
        component:()=>import("@/components/Product.vue")
    },
    {
        path:'/news',
        name:'news',
        component:()=>import("@/components/news.vue")
    },
    {
        path:'/science',
        name:'science',
        component:()=>import("@/components/Science.vue")
    },
    {
        path:'/entrance',
        name:'entrance',
        component:()=>import("@/components/Entrance.vue")
    },
]

const router = createRouter({
    history:createWebHashHistory('/'),
    routes
})

export default router
